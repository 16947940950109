//import "npm:jquery/dist/jquery.min.js"; // for now including via script tag
import "npm:bootstrap/dist/js/bootstrap.min.js";
//import "npm:wowjs/dist/wow.min.js";
let wowjs = require('wowjs');
window.WOW = wowjs.WOW;
import "npm:bootstrap/js/dist/scrollspy.js";
//import "npm:slick-carousel/slick/slick.min.js";
//import "npm:slick-animation/slick-animation.min.js";
//import "npm:owl.carousel/dist/owl.carousel.min.js"; // Importing via following 2 lines resolves an error
let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;
//import "../assets/js/custom"; // for now including via script tag